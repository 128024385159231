import { Injectable } from '@angular/core';
import { CrmDictionary, CrmSafeAny } from 'common-module/core/types';
import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';
import { omit } from 'lodash-es';
import { map, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EventAggregation } from './event.aggregation';
import { CreateEventModel, EventModel, UpdateEventModel } from './event.model';
import { EventType } from './event.type';

@Injectable({ providedIn: 'root' })
export class EventsApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'principals',
  })
  protected readonly endpoint!: CrmEndpoint<CrmSafeAny>;

  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'calendars',
  })
  protected readonly calendarsEndpoint!: CrmEndpoint<CrmSafeAny>;

  list<Event extends EventModel>(options: {
    type: EventType;
    principal: string;
    params?: CrmDictionary;
  }): Observable<Event[]> {
    const { params, principal, type } = options;

    return this.endpoint.request('GET', this.buildUrl(principal, type), {
      responseType: 'json',
      params,
    });
  }

  aggregate<Model extends EventModel>({
    users,
    type,
    params,
  }: {
    type: EventType;
    users: string[];
    params?: CrmDictionary;
  }) {
    return this.calendarsEndpoint.request<EventAggregation<Model>>(
      'POST',
      [type, 'aggregate'].join('/'),
      { responseType: 'json', body: { users }, params }
    );
  }

  get<Event extends EventModel>(options: {
    id: string;
    type: EventType;
    principal: string;
    params?: CrmDictionary;
  }) {
    const { id, params, principal, type } = options;

    return this.endpoint
      .request<Event[]>('GET', this.buildUrl(principal, type), {
        responseType: 'json',
        params: { ...params, uid: id },
      })
      .pipe(map((events) => events[0]));
  }

  create<Event extends EventModel>(options: {
    type: EventType;
    principal: string;
    body: CreateEventModel<Event>;
  }) {
    const { type, principal, body } = options;

    return this.endpoint
      .request<{ events: Event[] }>('POST', this.buildUrl(principal, type), {
        responseType: 'json',
        body: {
          ...omit(body, ['uid', 'href', 'dtStamp']),
          rrule: body.rrule ? omit(body.rrule, 'dtstart') : null,
        },
      })
      .pipe(map(({ events }) => events[0]));
  }

  update<Event extends EventModel>(options: {
    id: string;
    type: EventType;
    principal: string;
    body: UpdateEventModel<Event>;
  }): Observable<Event> {
    const { type, principal, body, id } = options;

    return this.endpoint
      .request<{ events: Event[] }>(
        'PUT',
        [this.buildUrl(principal, type), id].join('/'),
        {
          responseType: 'json',
          body: {
            ...omit(body, ['href', 'dtStamp']),
            rrule: body.rrule ? omit(body.rrule, 'dtstart') : null,
          },
        }
      )
      .pipe(map(({ events }) => events[0]));
  }

  changeEventStatus<Event extends EventModel>({
    type,
    status,
    event,
    principal,
  }: {
    type: EventType;
    event: Event;
    status: Event['status'];
    principal: string;
  }) {
    return this.update<Event>({
      body: { ...event, status } as UpdateEventModel<Event>,
      principal,
      type,
      id: event.uid,
    });
  }

  resolveEvent({
    id,
    type,
    principal,
  }: {
    id?: string;
    type: EventType;
    principal: string;
  }): Observable<EventModel | undefined> {
    if (!id) {
      return of(undefined);
    }

    return this.get({
      type,
      principal,
      id,
    }).pipe(catchError(() => of(undefined)));
  }

  private buildUrl(principal: string, type: EventType) {
    return [principal, 'calendars', type, 'events'].join('/');
  }
}
